import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Icon } from "@iconify-icon/react";
import { Link } from "gatsby";
import * as styles from "./Footer.module.scss";

const links = [
  { title: "Privacy", href: "/privacy/" },
  { title: "FOI", href: "/freedom/" },
  { title: "Disclaimer", href: "/disclaimer/" },
  { title: "Accessibility", href: "/accessibility/" },
];

const socials = [
  {
    title: "Facebook",
    href: "https://www.facebook.com/Varndean",
    icon: "entypo-social:facebook-with-circle",
  },
  {
    title: "Twitter",
    href: "https://twitter.com/varndean",
    icon: "entypo-social:twitter-with-circle",
  },
  {
    title: "YouTube",
    href: "https://www.youtube.com/c/varndeancollege",
    icon: "entypo-social:youtube-with-circle",
  },
  {
    title: "Instagram",
    href: "https://www.instagram.com/varndeancollege/",
    icon: "entypo-social:instagram-with-circle",
  },
];

export default function Footer() {
  return (
    <footer className={styles.wrapper}>
      <Container className="py-5">
        <Row>
          <Col lg={8} className="my-auto">
            <span className="me-2 mb-1 mb-md-auto d-block d-md-inline-block">
              &copy; {new Date().getFullYear()} - Varndean College.
            </span>
            {links.map((link) => (
              <Link className="me-2" to={link.href} key={link.title}>
                {link.title}
              </Link>
            ))}
            <a
              target="_blank"
              href="https://reports.ofsted.gov.uk/provider/40/130668"
              rel="noopener"
            >
              Ofsted
            </a>
          </Col>
          <Col lg={4}>
            <div
              className={`${styles.socialBtns} mt-4 mt-lg-auto float-lg-end`}
            >
              {socials.map((social) => (
                <a
                  title={social.title}
                  href={social.href}
                  key={social.title}
                  target="_blank"
                >
                  <Icon icon={social.icon} height="32" className="ms-2" />
                </a>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
